<template>
  <DashboardContainer :pageTitle="pageTitle">
    <!-- Spinner for loading state -->
    <Spinner v-if="loading" class="mt-24" />

    <template v-else>
      <!-- Date Inputs -->
      <div class="flex flex-wrap gap-4 mb-6">
        <div class="flex items-center gap-2 flex-grow">
          <label for="start" class="text-gray-700">Start Date:</label>
          <FlatPickr
            id="start"
            name="start"
            class="input--text--small placeholder:text-gray-400 date-input flex-grow"
            placeholder="Select date start"
            :value="selectedDate.date1"
            @input="handleDateChange('date1', $event.target.value)"
          />
        </div>
        <div class="flex items-center gap-2 flex-grow">
          <label for="end" class="text-gray-700">End Date:</label>
          <FlatPickr
            id="end"
            name="end"
            class="input--text--small placeholder:text-gray-400 date-input flex-grow"
            placeholder="Select date end"
            :value="selectedDate.date2"
            @input="handleDateChange('date2', $event.target.value)"
          />
        </div>
      </div>

      <!-- Navigation -->
      <div class="flex justify-between items-center mb-4">
        <h1 style="font-size: 1.5rem; font-weight: bold; text-decoration: underline">
          Fundraiser Performance Metrics
        </h1>
        <RouterLink :to="{ name: 'CampaignCharts' }" class="btn--table">
          Campaign Charts
        </RouterLink>
      </div>

      <!-- Recurring Donations Section -->
      <h2 class="text-xl font-bold mt-6 mb-4">Recurring Donations</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="scrollable-chart-container">
          <h3 class="title font-bold mb-4">Number of Donations</h3>
          <div class="chart-outer-wrapper">
            <div class="chart-inner-wrapper">
              <BarChart
                :chart-data="numberOfDonationsData"
                v-if="numberOfDonationsData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>
        </div>

        <!-- Repeat similar structure for other recurring donation charts -->
        <div class="scrollable-chart-container">
          <h3 class="title font-bold mb-4">Donors Over 30</h3>
          <div class="chart-outer-wrapper">
            <div class="chart-inner-wrapper">
              <BarChart :chart-data="donorsOver30Data" v-if="donorsOver30Data.labels.length > 0" />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>
        </div>

        <div class="scrollable-chart-container">
          <h3 class="title font-bold mb-4">Average Donor Age</h3>
          <div class="chart-outer-wrapper">
            <div class="chart-inner-wrapper">
              <BarChart
                :chart-data="averageDonorAgeData"
                v-if="averageDonorAgeData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>
        </div>

        <div class="scrollable-chart-container">
          <h3 class="title font-bold mb-4">Average Donation Amount</h3>
          <div class="chart-outer-wrapper">
            <div class="chart-inner-wrapper">
              <BarChart
                :chart-data="averageDonationAmountData"
                v-if="averageDonationAmountData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- One-Time Donations Section -->
      <h2 class="text-xl font-bold mt-8 mb-4">One-Time Donations</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="scrollable-chart-container">
          <h3 class="title font-bold mb-4">Number of One-Time Donations</h3>
          <div class="chart-outer-wrapper">
            <div class="chart-inner-wrapper">
              <BarChart
                :chart-data="numberOfOneTimeDonationsData"
                v-if="numberOfOneTimeDonationsData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>
        </div>

        <div class="scrollable-chart-container">
          <h3 class="title font-bold mb-4">Average One-Time Donation Amount</h3>
          <div class="chart-outer-wrapper">
            <div class="chart-inner-wrapper">
              <BarChart
                :chart-data="averageOneTimeDonationAmountData"
                v-if="averageOneTimeDonationAmountData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>
        </div>

        <div class="scrollable-chart-container">
          <h3 class="title font-bold mb-4">One-Time Donation Sum</h3>
          <div class="chart-outer-wrapper">
            <div class="chart-inner-wrapper">
              <BarChart
                :chart-data="oneTimeDonationSumData"
                v-if="oneTimeDonationSumData.labels.length > 0"
              />
              <div v-else class="flex items-center justify-center h-full text-gray-500">
                No data available
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Back Button -->
      <div class="flex justify-end mt-10">
        <RouterLink :to="{ name: 'CampaignList' }" class="btn--table">
          Back to Campaigns
        </RouterLink>
      </div>
    </template>
  </DashboardContainer>
</template>

<script>
import { ref, onBeforeMount, reactive } from 'vue'
import { BarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import DashboardContainer from '../components/DashboardContainer.vue'
import Spinner from '@/components/Spinner'
import { DonationApi } from '@/services/donations/'
import { useStore } from 'vuex'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

Chart.register(...registerables)

export default {
  name: 'FundraiserPerformanceCharts',
  components: {
    DashboardContainer,
    Spinner,
    BarChart,
    FlatPickr,
  },
  setup() {
    const store = useStore()
    const loading = ref(false)
    const today = new Date().toISOString().split('T')[0]
    const selectedDate = reactive({
      date1: today,
      date2: '',
    })
    const pageTitle = ref('Fundraiser Performance Charts')

    // Initialize chart data with empty structure
    const initializeChartData = (label, backgroundColor) => ({
      labels: [],
      datasets: [
        {
          label,
          backgroundColor,
          data: [],
        },
      ],
    })

    // Chart data refs
    const numberOfDonationsData = ref(initializeChartData('Number of Donations', '#2596be'))
    const donorsOver30Data = ref(initializeChartData('Donors Over 30', '#2596be'))
    const averageDonorAgeData = ref(initializeChartData('Average Donor Age', '#2596be'))
    const averageDonationAmountData = ref(initializeChartData('Average Donation Amount', '#2596be'))
    const numberOfOneTimeDonationsData = ref(
      initializeChartData('Number of One-Time Donations', '#2596be'),
    )
    const averageOneTimeDonationAmountData = ref(
      initializeChartData('Average One-Time Donation Amount', '#2596be'),
    )
    const oneTimeDonationSumData = ref(initializeChartData('One-Time Donation Sum', '#2596be'))

    const fetchData = async (startDate, endDate) => {
      loading.value = true
      try {
        const response = await DonationApi.csc.get_fundraiser_performance_metrics({
          start_date: startDate,
          end_date: endDate,
        })

        let metrics = []
        if (Array.isArray(response)) {
          metrics = response
        } else if (response?.data && Array.isArray(response.data)) {
          metrics = response.data
        } else if (response?.data?.data && Array.isArray(response.data.data)) {
          metrics = response.data.data
        }

        if (metrics.length === 0) {
          console.warn('No valid metrics data found in response')
        }

        updateChartData(numberOfDonationsData, metrics, 'recurring_metrics.number_of_donations')
        updateChartData(donorsOver30Data, metrics, 'recurring_metrics.donors_over_30')
        updateChartData(averageDonorAgeData, metrics, 'recurring_metrics.average_donor_age')
        updateChartData(
          averageDonationAmountData,
          metrics,
          'recurring_metrics.average_donation_amount',
        )
        updateChartData(
          numberOfOneTimeDonationsData,
          metrics,
          'one_time_metrics.number_of_donations',
        )
        updateChartData(
          averageOneTimeDonationAmountData,
          metrics,
          'one_time_metrics.average_donation_amount',
        )
        updateChartData(oneTimeDonationSumData, metrics, 'one_time_metrics.total_donation_sum')
      } catch (error) {
        console.error('Error fetching data:', error)
        const allChartData = [
          numberOfDonationsData,
          donorsOver30Data,
          averageDonorAgeData,
          averageDonationAmountData,
          numberOfOneTimeDonationsData,
          averageOneTimeDonationAmountData,
          oneTimeDonationSumData,
        ]
        allChartData.forEach((chart) => {
          chart.value.labels = []
          chart.value.datasets[0].data = []
        })

        alert('Failed to fetch data. Please try again later.')
      } finally {
        loading.value = false
      }
    }

    const updateChartData = (chartData, metrics, metricPath) => {
      try {
        if (!metrics || !Array.isArray(metrics)) {
          console.warn('Metrics data is not an array:', metrics)
          chartData.value.labels = []
          chartData.value.datasets[0].data = []
          return
        }

        const validMetrics = metrics.filter((item) => {
          try {
            const value = metricPath.split('.').reduce((obj, key) => obj[key], item)
            return value !== undefined && value !== null
          } catch {
            return false
          }
        })

        chartData.value.labels = validMetrics.map((f) => `${f.first_name} ${f.last_name}`)

        chartData.value.datasets[0].data = validMetrics.map((item) => {
          return metricPath.split('.').reduce((obj, key) => obj[key], item)
        })

        console.log(`Updated ${chartData.value.datasets[0].label} chart with:`, {
          labels: chartData.value.labels,
          data: chartData.value.datasets[0].data,
        })
      } catch (error) {
        console.error(`Error updating ${chartData.value.datasets[0].label} chart:`, error)
        chartData.value.labels = []
        chartData.value.datasets[0].data = []
      }
    }

    const handleDateChange = (dateField, value) => {
      selectedDate[dateField] = value
      fetchData(selectedDate.date1, selectedDate.date2)
    }

    onBeforeMount(async () => {
      await fetchData(selectedDate.date1, selectedDate.date2)
    })

    return {
      loading,
      pageTitle,
      numberOfDonationsData,
      donorsOver30Data,
      averageDonorAgeData,
      averageDonationAmountData,
      numberOfOneTimeDonationsData,
      averageOneTimeDonationAmountData,
      oneTimeDonationSumData,
      selectedDate,
      handleDateChange,
    }
  },
}
</script>

<style scoped>
.scrollable-chart-container {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.chart-outer-wrapper {
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.chart-inner-wrapper {
  height: 100%;
  min-width: 800px;
}

/* Scrollbar styling */
.chart-outer-wrapper::-webkit-scrollbar {
  height: 8px;
}

.chart-outer-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.chart-outer-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.chart-outer-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.title {
  font-size: 1.1rem;
  color: #333;
}
</style>
